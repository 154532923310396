import axios from 'axios';
import constants from './constants';
import cachios from 'cachios';
const client = axios.create({
    baseURL: constants.baseURL
});
const cachiosInstance = cachios.create(client);
const api={
    get: async (url, ttl) => {
        if(ttl) {
            return cachiosInstance.get(url, { ttl: ttl })
        } else {
            return client.get(url);
        }
    },
     post:async(url,body)=>{
        const options = {
            headers: { 
              'Content-Type': 'application/json'
            }
          };
        return client.post(url,body,options)
    }
}

export default api;